import React, { Component } from 'react'

import Inbox from '../../Inbox'


export default class ChatView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedConversation: -1,
      currentEpisode:this.props.currentEpisode,
      
    };

    this.inbox = React.createRef();
  }



  

  updateEpisodeFromStorage = (newStep) => {
    this.setState({ currentEpisode:newStep})
    this.inbox.current.updateGameContext(newStep)
  }

  updateEpisode = (newStep) => {
  	
    this.props.updateEpisode(newStep)
  }

  openAttachement = (id) => {
    this.props.openAttachement(id)
  }

  newMessageHandler = () => {
    this.props.newMessageHandler()
  }

  render() {
    return (
    	<>

    	<Inbox classToSet={this.props.classToSet} userEmail={this.props.userEmail} currentEpisode={this.state.currentEpisode}  updateEpisode={this.updateEpisode.bind(this)} openAttachement={this.openAttachement.bind(this)}  newMessageHandler={this.newMessageHandler.bind(this)} ref={this.inbox} />



    	</>
    
    );
  }
  
}