import base64 from 'base-64';


export function getUser(password, users) {
	var user;

	const pw = encodeURIComponent(password.toUpperCase()).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode('0x' + p1);
    });
    var base64Pw = base64.encode(pw)

	for (user of users) {
		if(base64Pw === user.pw) {
			return user
		}
	}
	return null;
}

export function getSeasonUserFromPw(password, users){
	var user;
	for (user of users) {
		if(user.pw.toLowerCase() === password.toLowerCase()){
			return user
		}
	}
	return null;
}


export function doesSeasonPasswordExist( pw, users){
	var user;
	for (user of users) {
		if(user.pw.toLowerCase() === pw.toLowerCase()){
			return true
		}
	}
	return false;
}

export function getGetChildrenFromFolder(parentId, icons) {
	var childrens = [];
	var icon;
	for (icon of icons) {
		if(icon.parent === parentId){
			childrens.push(icon);
		}
	}
	return childrens;
}