import React from 'react';

export default function ChatConversations(props) {

  const selectedConversation = props.selectedConversation
  return (
  	<>

  
  	<div className={selectedConversation === -1 ? "defaultSelected chat-thread flex flex-row items-center py-6 px-4 cursor-pointer " : ( selectedConversation === "AKJPQ" ? "selected chat-thread flex flex-row items-center py-6 px-4 cursor-pointer" : "chat-thread flex flex-row items-center py-6 px-4 cursor-pointer")} onClick={function(){props.setCurrentConversation("AKJPQ")}}>

  		<div className="mr-6 avatar" >A</div>

  		<div>
  			<p className="userName font-medium md:text-xl">AKJPQ</p>
  		</div>
  	</div>


    <div className={selectedConversation === "YNWZZPK" ? "selected chat-thread flex flex-row items-center py-6 px-4 cursor-pointer" : "chat-thread flex flex-row items-center py-6 px-4 cursor-pointer"} onClick={function(){props.setCurrentConversation("YNWZZPK")}}>

      <div className="mr-6 avatar" >Y</div>

      <div>
        <p className="userName font-medium md:text-xl">YNWZZPK</p>
      </div>
    </div>

  	

  	</>
  
  );
  
}
