import React, { useState, useRef } from 'react';
import Div100vh from 'react-div-100vh';
import CheeseburgerMenu from '../components/CheeseburgerMenu';
import BlogExcerpt from '../components/Blog/BlogExcerpt';
import BlogPostView from '../components/Blog/BlogPostView';
import BlogUploadAccess from '../components/Blog/BlogUploadAccess';
import { blogPosts } from '../datas/season10/blogPosts.json';


export default function AshleyBlogView(props) {

	const [currentView, setCurrentView] = useState("home")
	const [menuOpen, setMenuOpen] = useState(false)
	const [selectedBlogPost, setSelectedBlogPost] = useState( ((props.selectedBlogItem === -1 || Number.isNaN(parseInt(props.selectedBlogItem)) ) ? -1 : blogPosts[parseInt(props.selectedBlogItem)]  ) )
	const [showUploadPage, setShowUploadPage] = useState( (props.selectedBlogItem === "upload_page" ? true : false ) ) 


	const [currentEpisode, setCurrentEpisode] = useState(props.currentEpisode)


	const openMenu = () => {
		setMenuOpen(true)
	}

	const closeMenu = () => {
		setMenuOpen(false)
	}

	const goToView = (viewName) => {
		document.getElementById("ashleyBlogSite").scrollTo({
          behavior: "auto",
          top:  0
        });

		setCurrentView(viewName);
		setSelectedBlogPost(-1);
		closeMenu();
	}

	const tapReadMore = (i) => {
		document.getElementById("ashleyBlogSite").scrollTo({
          behavior: "auto",
          top:  0
        });

		setSelectedBlogPost(blogPosts[i])
	}

	const completeBlogUploadAccess = () => {
		//here update the episode number (6.3) + send email 
		setShowUploadPage(false)
		setCurrentEpisode(currentEpisode+0.1)
		props.updateMessageEpisode(currentEpisode+0.1)
	}

	const blogExcerptViews = blogPosts.map((blogPosts, index) => {
		if(blogPosts.episode <= currentEpisode){
		  return (
	          <BlogExcerpt blogEntry={blogPosts} key={index} tapReadMore={tapReadMore.bind(this)} /> 
	       )
		}
	})

	blogExcerptViews.reverse()


	if(showUploadPage) {
		return (
			<Div100vh  dontresize="true" className="h-full w-full flex flex-col ashleyBlogSite ashleyBlogSiteUpload" id="ashleyBlogSite"> 
				<BlogUploadAccess completeBlogUploadAccess={completeBlogUploadAccess.bind(this)}  tapBack={function(){setShowUploadPage(false)}} />
			</Div100vh>
		)
	}else {
		return (
        
		<Div100vh  dontresize="true" className="h-full w-full flex flex-col ashleyBlogSite" id="ashleyBlogSite"> 
            <div className="header">
		  		<img className="menu-toggle menu-toggle-icon mobile" alt="menu-toggle" onClick={openMenu.bind(this)} src="https://cdn.huntakiller.com/huntakiller/s10/menu-hamburger-white.svg"/>
		  		<img className=" close-icon "  alt="close-dt" onClick={props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s10/closebutton-white.svg"/>	
	        	<div className="desktop">
					<ul className="menu">
						<li className={ "home" === currentView ? 'current' : '' } onClick={function() { goToView("home");}}>HOME</li>   
						<li className={ "contact" === currentView ? 'current' : '' } onClick={function() { goToView("contact");}}>CONTACT</li>   
					</ul>
	        	</div>
	        		
		  	</div> 
           


	  	    <CheeseburgerMenu isOpen={menuOpen} closeCallback={closeMenu.bind(this)} className="light-content">
		        <ul className="menu">
					<li className={ "home" === currentView ? 'current' : '' } onClick={function(){goToView("home"); }}>HOME</li>   
					<li className={ "contact" === currentView ? 'current' : '' } onClick={function(){goToView("contact");}}>CONTACT</li>   
				</ul>
	        </CheeseburgerMenu>
	 

	        <h1 className={ selectedBlogPost != -1 ? 'desktop' : '' } >Mayor, She Wrote</h1>


            <div className="ashleyBlog-container">
            	<div  className={ selectedBlogPost != -1 ? 'blog-container mt-0  pt-0  md:mx-12 md:mt-0 md:pt-4' : 'blog-container mt-4  pt-4  md:mx-12 md:mt-0 ' }>


            		{currentView === "home" ? 
            		(
            			selectedBlogPost === -1 ? 
	        			(
	        				<>
	            				<p className="breadcrumbs px-4 orange  text-xs md:px-0 md:text-lg mb-5">Latest Blog Posts</p>
			            		<div className="">
			            			{blogExcerptViews}
			            		</div>
	        				</>

	        			) : 
	        			(
	        				<BlogPostView blogEntry={selectedBlogPost} tapBack={function(){goToView("home");}}/>
	        			)

            		):
            		(

            			<div className="my-12">

            				<h2 className="text-center font-bold text-3xl mb-10" >ERROR</h2>

            				<p className="text-center text-lg">You must to be logged in to send a message or leave a comment.<br/><br/><span className="italic">Registration for this site is currently closed.</span></p>

            			</div>

            		)}


            		



            	</div>

            	<div className={ selectedBlogPost != -1 ? 'desktop side-bar' : 'side-bar' }>

            		<div className="flex flex-row md:flex-col items-center">
	            		<img className="mx-4  md:mb-5" src="https://cdn.huntakiller.com/huntakiller/s10/ashleyblog/ashleyblogpfp.png" />
	            		<p className="font-bold text-sm mr-2 md:mx-2 md:text-lg">Welcome to my behind-the-scenes news blog. My name is Ashley Marie Mendoza and I'm a freelance journalist who lives in my hometown of Copper Cliffs, Arizona.</p>
            		</div>
            		
            		<p className=" text-xs mt-5 mx-4 italic md:mt-6 md:text-lg">I've covered all kinds of subjects throughout my career, but I'm particularly interested in local politics and grassroots organizing. You can currently read my latest articles in the Copper Cliffs Chronicle and the Copper Cliffs Community Catalogue.
<br/>But in this blog, I promise you an unfiltered, uncensored look at what's really going on with the politics of Copper Cliffs, including the current election for our next mayor.
<br/>If you've got a tip for me, feel free to private message me or leave a comment on any of my posts!</p>

            	</div>

            </div>
 
            <div className="footer"></div>

        </Div100vh>
                
    )
	}


    
}



