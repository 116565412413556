import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import '../node_modules/tailwindcss/dist/tailwind.min.css'
import './css/index.css'
import 'react-image-lightbox/style.css'; 
import EpisodeLogin from './EpisodeLogin'
import KeepSafePro from './KeepSafePro'
import Notfound from './Notfound'
import AshleysOfflineMessageView from 'views/AshleysOfflineMessageView'

const routing = (
	<Router>
	  <Switch>
	      <Route exact path="/" component={EpisodeLogin} />
	      <Route exact path="/keepsafepro" component={KeepSafePro} />
		  <Route exact path="/AshleyOffline" component={AshleysOfflineMessageView} />
	      <Route component={Notfound} />
	  </Switch>
  	</Router>
)

ReactDOM.render(routing, document.getElementById('root'))