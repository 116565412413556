import React, { useState } from 'react';

const PASSWORD = "haeeti";

export default function BlogUploadAccess(props) {

	const [password, setPassword] = useState("");
	const [errorPw, setErrorPw] = useState(false);
	const [showUpload, setShowUpload] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isFileSelected, setIsFileSelected] = useState(false);
	const [uploading, setUploading] = useState(0);

	const handleChange = (event) => {
    	setPassword(event.target.value)
    	setErrorPw(false)
	}

	const handleKeyPress = (event) => {
		if(event.key === 'Enter'){
		  document.activeElement.blur();
		  event.preventDefault();
		}
	}


	const handleSubmit = (event) => {
		event.preventDefault();
		document.activeElement.blur();

		
		if(PASSWORD.toLowerCase() === password.toLowerCase()) {
			
			setTimeout(
			    function() {
			        setShowUpload(true)
			    }
			    .bind(this),
			    500
			);
		}else {
			setErrorPw(true)
		}

	}

	const startUpload = () => {

		if(isFileSelected && uploading === 0) {
			updateUpload(1)

		}
		
	}


	const updateUpload = (i) => {
		setUploading(i)
		if (i < 100) {
			setTimeout(function() {updateUpload(i + 1)}, 70);
		}else {
			setTimeout(function() {props.completeBlogUploadAccess()}, 800);
			
		}
	}



	if(showUpload) {



		return (
			<>

				<div className="header flex flex-col pt-4">
					<img src="https://cdn.huntakiller.com/huntakiller/s10/ashleyblog/circulrsitelogo.svg" className="logo "/>

					<div className="flex items-center mt-6 mb-4">
						<img src="https://cdn.huntakiller.com/huntakiller/s10/ashleyblog/blogmailicon.png" width="30" />
						<p className="text-lg text-white md:text-xl ml-4">Hello <b>ASHLEY!</b></p>
					</div>
				</div>


				<div className="flex-1 flex items-center justify-center flex-col">
					<p className="text-lg md:text-xl">Please select a file:</p>

					<div className="dropdown z-10 mt-4">
						<div className="rounded-3xl border border-gray-300 rounded shadow bg-white">
							<div className="py-4 px-6 text-lg md:text-xl border-b text-gray-500 " onClick={function() {setDropdownOpen(!dropdownOpen)}}>{!isFileSelected ? "file name" : "Frannie_Files.zip"}</div>
							{dropdownOpen && (<div className="py-4 px-6 text-lg md:text-xl  hover:bg-gray-300" onClick={function() {setIsFileSelected(true); setDropdownOpen(false)}}>Frannie_Files.zip</div>)}
						</div>
					</div>

					<button className="rounded-3xl px-12 mt-4 text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline orangebg" onClick={startUpload} disabled={false}>UPLOAD</button>


					{uploading > 0 && (
						<>
							<div className="relative pt-8 progress-bar">
							  <div className="overflow-hidden h-8 mb-4 text-xs flex rounded border shadow border-gray-300">
							    <div style={{width:uploading+"%"}} className={uploading >= 100 ? "shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-300" : "shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gray-300"}></div>
							  </div>
							</div>

							<p className="text-center">Uploading... {uploading}% Complete</p>
						</>

					)}
					
				</div>

				<button onClick={props.tapBack} className="px-4 md:px-0 mb-6 orange focus:outline-none focus:shadow-outline underline uppercase">{'<<'}Back</button>

				<div className="footer"></div>

			</>

			)

	}else {

		return (

			<> 

			<div className="header">

				<img src="https://cdn.huntakiller.com/huntakiller/s10/ashleyblog/circulrsitelogo.svg" className="logo"/>
			</div>

			<div className="flex-1 flex items-center justify-center flex-col">

			<p className="text-center">Hello, <b>ASHLEY!</b></p>

			<form onSubmit={handleSubmit}>
				<div className="">
					<div className=" mb-4 mt-8">
					<input className="rounded-3xl bg-gray-200 rounded shadow appearance-none border-none  w-full py-3 px-3  leading-tight focus:outline-none focus:shadow-outline" value={password}
					onChange={handleChange} type="password" autoFocus id="password" placeholder="**********" onKeyPress={handleKeyPress}/>
					  <p className="error mt-2">{errorPw && "Invalid Password"} &nbsp;</p>
					</div>
				</div>
				<button className="rounded-3xl px-12  text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline" type="submit" disabled={false}>ENTER</button>
			</form>




			</div>

			<button onClick={props.tapBack} className="px-4 md:px-0 mb-6 orange focus:outline-none focus:shadow-outline underline uppercase">{'<<'}Back</button>

			 <div className="footer"></div>

			</>);




	}

	
  
}


//<p onClick={props.completeBlogUploadAccess}> HELLO </p>

