import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup } from 'react-bootstrap';
// HAK services
import { BotService } from '../../services/bot-service';
import { ConfigurationService } from '../../services/configuration-service';
// HAK Utilities
import { Utility } from '../common/utility';
// HAK models
import { AnswersVM } from '../../models/answer';
import { ConfigurationVM } from '../../models/configuration';
import { ContentTagVM } from '../../models/content-tag';
import { Message } from '../../models/message';
import { Player, PlayerVM } from '../../models/player';
import { Question, QuestionVM, StrictFilter } from '../../models/question';
// Utilities
import moment from 'moment';
// HAK styles
import '../../styles/app.scss';
import './chat-client.scss';

interface Props {
    player: PlayerVM;
    configurationLocation: string;
    botChatResponse: FunctionStringCallback;
    openAttachement: FunctionStringCallback
    currentEpisode: number;
}

interface State {
    characterName: string;
    characterEmail: string;
    characterAvatar: string;
    player: PlayerVM;
    configurationLocation: string;
    currentEpisode:number;
    endpointRoot: string;
    botKnowledgeBaseId: string;
    botAuthorizationEndpointKey: string;
    botScoreThreshold: number;
    botRankerType: string;
    botMetaTag: string;
    botMetaTagValue: string;
    gameContext: string;
    chatConversationStarter: string;
    chatReplyWhenNoBotAnswerDefault: string;
    killSwitchMessageEpisode6: string;
    receiveChatResponseDelay: number;
    theAsk: string;
    conversation: Array<Message>;
    firstAnswerProvided: boolean;
    contentTagReplacements: Array<ContentTagVM>;
    error : boolean;
}

/**
 * Chat client
 *
 * Overview: Simulates a typical chat interface.
 *
 */
export default class ChatClient extends Component<Props, State>  {
    botService: any;
    configurationService: any;
    messagesEnd: any;
    constructor(props: Props) {
        super(props);
        this.botService = new BotService();
        this.configurationService = new ConfigurationService();
        this.messagesEnd = null
        this.state = {
            characterName: '',
            characterEmail: '',
            characterAvatar: '',
            player: new Player(),
            configurationLocation: '',
            botKnowledgeBaseId: '',
            botAuthorizationEndpointKey: '',
            endpointRoot: '',
            currentEpisode:0,
            botScoreThreshold: 0,
            botRankerType: '',
            botMetaTag: '',
            botMetaTagValue: '',
            gameContext: '',
            chatConversationStarter: '',
            chatReplyWhenNoBotAnswerDefault: '',
            killSwitchMessageEpisode6: '',
            receiveChatResponseDelay: 0,
            theAsk: '',
            conversation: [],
            firstAnswerProvided: false,
            contentTagReplacements: [],
            error:false,
        };
        this.prepareChatClient = this.prepareChatClient.bind(this);
        this.loadConversationStarter = this.loadConversationStarter.bind(this);
        this.unexpectedErrorEncountered = this.unexpectedErrorEncountered.bind(this);

        // Event handlers
        this.onMessageChange = this.onMessageChange.bind(this);
        this.clickHandler = this.clickHandler.bind(this)
        this.getAnswer = this.getAnswer.bind(this);
        this.formatQuestion = this.formatQuestion.bind(this);
        this.askQuestion = this.askQuestion.bind(this);
        this.saveAnswer = this.saveAnswer.bind(this);
        this.addAnswersToConversation = this.addAnswersToConversation.bind(this);
    }

    /**
     * componentDidMount Prepare chat client.
     * @returns n/a.
     */
    componentDidMount(): void {
        this.prepareChatClient();
    }

    componentWillUnmount(): void {
    }

    /**
     * prepareChatClient Gather initial setting \ values.
     * @returns n/a.
     */
    prepareChatClient(): void  {
        const { player, configurationLocation, currentEpisode } = this.props;
        this.configurationService.get(configurationLocation)
            .then((response: any) => response.json())
            .then((configuration: ConfigurationVM) => {

                var botScoreThreshold = (typeof configuration.botConfiguration.scoreThreshold[currentEpisode] === 'undefined' ) ? configuration.botConfiguration.defaultScoreThreshold : Number(configuration.botConfiguration.scoreThreshold[currentEpisode]);

                this.setState({
                    characterName: configuration.characters[0].name,
                    characterEmail: configuration.characters[0].email,
                    characterAvatar: configuration.characters[0].avatar,
                    contentTagReplacements: configuration.contentTagReplacements,
                    player: player,
                    currentEpisode: currentEpisode,
                    configurationLocation: configurationLocation,
                    endpointRoot: configuration.botConfiguration.endpointRoot,
                    botKnowledgeBaseId: configuration.botConfiguration.knowledgeBaseId,
                    botAuthorizationEndpointKey: configuration.botConfiguration.authorizationEndpointKey,
                    botScoreThreshold: botScoreThreshold,
                    botRankerType: configuration.botConfiguration.rankerType,
                    botMetaTag: configuration.botConfiguration.metaTag,
                    botMetaTagValue: configuration.botConfiguration.metaTagValue,
                    gameContext: configuration.botConfiguration.metaTagValue+"0"+String(currentEpisode),
                    chatConversationStarter: configuration.chatConversationStarter[currentEpisode-1],
                    chatReplyWhenNoBotAnswerDefault: configuration.chatReplyWhenNoBotAnswerDefault,
                    killSwitchMessageEpisode6: configuration.killSwitchMessageEpisode6,
                    receiveChatResponseDelay: configuration.receiveChatResponseDelay
                });
                if (configuration.chatConversationStarter.length > 0) {
                    this.loadConversationStarter(configuration.chatConversationStarter[currentEpisode-1]);
                }
            }, (error: any) => {
                console.log(error);
                this.unexpectedErrorEncountered();
            }
        );
    }

    /**
     * loadConversationStarter Add conversation starter to the conversation.
     * @param chatConversationStarter The initial message.
     * @returns n/a.
     */
    loadConversationStarter(chatConversationStarter: string): void {
        const message = new Message();
        message.sent = false;

        message.content = Utility.replaceAnswerTags(1,chatConversationStarter, this.state.player, this.state.contentTagReplacements, this.state.chatReplyWhenNoBotAnswerDefault);



       // message.content = chatConversationStarter;
        message.delivery = moment().utc().format();
        const conversation = [];
        conversation.push(message);
        this.setState({conversation: conversation});
    }

    /**
     * unexpectedErrorEncountered Adds "Currently Out" message to conversation.
     * @returns n/a.
     */
    unexpectedErrorEncountered(): void {
        const message = new Message();
        message.sent = false;
        message.content = 'Sorry, I\'m currently out. Let\'s chat later.';
        message.delivery = moment().utc().format();
        const conversation = [];
        conversation.push(message);
        this.setState({conversation: conversation});
    }

    /**
     * onMessageChange Save input message to state.
     * @param event The form input event.
     * @returns n/a.
     */
    onMessageChange(event: any): void {

        this.setState({theAsk: event.target.value});
    }




    /**
     * getAnswer Get bot answer.
     * @param event The form event.
     * @returns n/a.
     */
    getAnswer(event: any): void {
        event.preventDefault();

        if(this.state.theAsk.length > 0 && this.state.theAsk.trim()) {
            const question = this.formatQuestion(this.state.theAsk);
            this.askQuestion(question, true);
        }

        
    }


    clickHandler(e:any): void {
    
        e.preventDefault();
        const el = e.target.closest("a");
        
        if (el && e.currentTarget.contains(el) && el.classList.contains('app_attachement')) {
            // ...do your state change...
            
            //app_attachement
            if (typeof this.props.botChatResponse === 'function') {
                this.props.openAttachement(el.href.split("#")[1]);
            }

        }
    }

    /**
     * formatQuestion Format the message as a bot question.
     * @param ask The email message composed by player.
     * @returns Question Formatted bot question.
     */
    formatQuestion(ask: string): Question {
        const question = new Question(); 
        question.question = 'subjectLine:'+ask;
        question.scoreThreshold = this.state.botScoreThreshold;
        question.RankerType = this.state.botRankerType;
        question.strictFilters = [];

        const strictFilter = new StrictFilter();
        strictFilter.name = this.state.botMetaTag;
        strictFilter.value = this.state.gameContext;
        // question.strictFilters.push(strictFilter);

        return question;
    }

    /**
     * askQuestion Add question to conversation and call bot service to get an answer.
     * @param question The question to ask the bot.
     * @returns n/a.
     */
    askQuestion(question: QuestionVM, addQuestion: Boolean): void {
        if(addQuestion) {
            this.addQuestionToConversation(true, question.question.replace('subjectLine:',''));
        }
        
        this.botService.askQuestion(question)
            .then((response: any) => response.json())
            .then((answers: AnswersVM) => {
                this.setState({theAsk: ''});
                this.saveAnswer(answers);
            }, (error: any) => {
                console.log(error);
                this.unexpectedErrorEncountered();
            }
        );
    }

    updateGameContext(newEpisode:number) {
        console.log("updateSubject"+newEpisode)
        this.setState({currentEpisode:newEpisode, gameContext : this.state.botMetaTagValue+"0"+String(newEpisode)});

    }


    sendKillSwitchMessage() {
        let that = this;
        setTimeout(function() {
            const message = new Message();
            message.sent = false;
            message.content = that.state.killSwitchMessageEpisode6;
            message.delivery = moment().utc().format();

            let conversation = that.state.conversation;
            conversation.push(message);

            that.setState({conversation: conversation}, () => that.messagesEnd.scrollIntoView({ behavior: "smooth" }));
            

            if (typeof that.props.botChatResponse === 'function') {
                that.props.botChatResponse("Ssss");
            }


        }, 10000); //10000);
    }

    sendEpilogueMessage() {
        const question = this.formatQuestion("epiloguemessageepisode6");
        this.askQuestion(question, false);
    }

    /**
     * addQuestionToConversation Add message to conversation.
     * @param sent Was the message sent by the player or received from the bot.
     * @param question The question posed dby the player.
     */
    addQuestionToConversation(sent: boolean, question: string): void {
        const message = new Message();
        message.sent = sent;
        message.content = question;
        message.delivery = moment().utc().format();
        const conversation = this.state.conversation;
        conversation.push(message);
        this.setState({conversation: conversation});
        
    }

    /**
     * saveAnswer Wait to process the bot response, then add the bot answer to the conversation.
     * @param question The message composed by player.
     * @param answers The answers returned by the bot.
     * @returns n/a.
     */
    saveAnswer(answers: AnswersVM): void {
        let that = this;
        setTimeout(function() {
            that.addAnswersToConversation(false, answers);
           
        }, this.state.receiveChatResponseDelay);
    }

    /**
     * addAnswersToConversation Format the bot answer in the form of a response message. If first good answer, the game context becomes this question.
     * @param question The message composed by player. The initial player message becomes the game context going forward.
     * @param sent Was the message sent by the player or received from the bot.
     * @param answers The answers returned by the bot.
     * @returns n/a.
     */
    addAnswersToConversation(sent: boolean, answers: AnswersVM): void {
        if (answers !== null && answers.hasOwnProperty('answers')) {
            const message = new Message();
            message.sent = sent;
            message.content = Utility.replaceAnswerTags(answers.answers[0].id, answers.answers[0].answer, this.state.player, this.state.contentTagReplacements, this.state.chatReplyWhenNoBotAnswerDefault);
            message.delivery = moment().utc().format();

            if (typeof this.props.botChatResponse === 'function') {
                this.props.botChatResponse(answers.answers[0].answer);
            }

            if (answers.answers[0].id === -1) {
                if (!this.state.firstAnswerProvided) {
                    

                    this.setState({
                        firstAnswerProvided: true
                    });
                }
            }

            let conversation = this.state.conversation;
            conversation.push(message);

            if (answers.answers[0].hasOwnProperty('context') && answers.answers[0].context !== null) {
                if (answers.answers[0].context.prompts !== null && answers.answers[0].context.prompts.length > 0) {
                    for (let prompt of answers.answers[0].context.prompts) {
                        const message = new Message();
                        message.sent = sent;
                        message.delivery = moment().utc().format();
                        message.content = prompt.displayText;
                        conversation.push(message);
                    }
                }
            }
            this.setState({conversation: conversation}, () => this.messagesEnd.scrollIntoView({ behavior: "smooth" }));
        }
    }

    render() {
        // Render Conversation.
        const renderConversation = () => {
            if (this.state.conversation) {
                return (
                    <Fragment>
                        <Container  className=' pt-4 mb-2'>
                            <>
                                {this.state.conversation.map((message, index) => {
                                    if (message.sent) {
                                        return <Fragment key={index.toString()}>
                                            <Row noGutters={true} className='mb-3 align-top' key={index.toString()}>
                                                <Col xs={{ span: 9, offset: 3 }} className=' p-3 hak-chat-sent'  dangerouslySetInnerHTML={{ __html: message.content }}>
                                                    
                                                </Col>
                                            </Row>
                                        </Fragment>;
                                    } else {
                                        return <Fragment key={index.toString()}>
                                            <Row noGutters={true} className='mb-3 align-top' key={index.toString()}>
                                                <Col xs={9} className=' p-3 hak-chat-received'  onClick={this.clickHandler} dangerouslySetInnerHTML={{ __html: message.content }}>
                                                </Col>
                                            </Row>
                                        </Fragment>;
                                    }
                                })}

                                <div id="messagesEnd" style={{ float:"left", clear: "both" }}
                                     ref={(el) => { this.messagesEnd = el; }}>
                                </div>

                            </>
                        </Container>
                    </Fragment>
                );
            }
            return null;
        };

        return (
            <React.Fragment>
                {renderConversation()}
                <Form  onSubmit={this.getAnswer} >
                    <FormGroup>
                        <div className='position-relative'>

                            <input name='message' id='message' className='rounded-pill hak-chat-input form-control' autoFocus value={this.state.theAsk} onChange={this.onMessageChange} />
                            
                            <span  className={(this.state.theAsk.length > 0 && this.state.theAsk.trim()) ? 'position-absolute send-button-chat' : 'position-absolute send-button-chat disabled'}>

                                <img src="https://cdn.huntakiller.com/huntakiller/s10/chat-send-button-icon.svg" onClick={this.getAnswer} />
                                
                            </span>
                        </div>
                    </FormGroup>
                </Form>
            </React.Fragment>
        );
    }
}