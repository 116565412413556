import React from "react";

export default function CheeseburgerMenu(props) {


  

  if(!props.isOpen){
      return null;
  }


  return ( <div className={"cheeseburgerMenu h-full w-full flex flex-col justify-center items-center "+props.className}>
    <div className="cheeseburgerMenu-inner">

      {props.className === "light-content" ? 
      (<img className=" close-icon "  alt="close-dt" onClick={props.closeCallback} src="https://cdn.huntakiller.com/huntakiller/s10/closebutton-white.svg"/>) 
      : 
      (<img className=" close-icon "  alt="close-dt" onClick={props.closeCallback} src="https://cdn.huntakiller.com/huntakiller/s10/closebutton.svg"/>)
      }
      {props.children}
    </div>

  </div>);
  
}