import { Question  } from '../models/question';

/**
 * Bot service
 *
 * Overview: Provides Bot related services
 *
 */

export class BotService {
    constructor() {
    }

    /**
     * Calls bot API to ask question.
     * @param botKnowledgeBaseId API knowledgeBase Id
     * @param botAuthorizationEndpointKey API authorization key
     * @param question Model housing bot question API model
     * @returns Status 200 if returned
     */
    askQuestion(question: Question) {
        
        return new Promise((resolve, reject) => {
            fetch('https://hak-qna-maker.azurewebsites.net/qnamaker/knowledgebases/cbd63cfe-7dfe-4881-b45c-97941127caab/generateAnswer', {
                method: 'POST',
                body: JSON.stringify(question),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'EndpointKey 69ccf40a-ac15-4259-841d-97e4ad9f382d'
                }
            }).then((response) => {
                if (response.ok) {
                    return resolve(response);
                } else {
                    return reject(response);
                }
            }).catch(e => {
                console.log(e)
            });
        });
    }
}
