import React from 'react'

    
export default function Icon(props) {


	const icon = props.icon
    
    let imgUrl = "https://cdn.huntakiller.com/huntakiller/s10/";
    if(icon.icon === "folder"){
        if(icon.passwordprotected === true) {
            imgUrl = imgUrl + "files-folder-icon.svg";
        }else{
            imgUrl = imgUrl + "files-folder-icon.svg";
        }
    }else if(icon.icon === "image"){
        imgUrl = imgUrl + "files-image-icon.svg";
    }else if(icon.icon === "website"){
        imgUrl = imgUrl + "files-website-icon.svg";
    }else if(icon.icon === "video"){
        imgUrl = imgUrl + "files-video-icon.svg"
    }
    
    return (
       
            <li id={`icon-${icon.id}`} onClick={() => props.iconSingleClick(icon.id)}>
                <div className="keep-safe-icon-container"><img src={imgUrl}  className="" alt={icon.name} /></div>
                <p>{icon.name} </p>
            </li>
       
    )


    
}
