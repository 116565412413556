import React, { Component } from 'react'
import { useHistory } from 'react-router-dom';
import Div100vh from 'react-div-100vh'
import Icon from '../components/Icon';
import StorageMenuContent from '../components/StorageMenuContent';
import CheeseburgerMenu from '../components/CheeseburgerMenu';
import MediaView from '../components/MediaView';
import AshleyBlogView from './AshleyBlogView';
import AshleysOfflineMessageView from './AshleysOfflineMessageView'
import { icons } from '../datas/season10/icons.json';


export default class StorageView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIcon: -1,
      currentEpisode:this.props.currentEpisode,
      menuOpen:false,
      selectedBlogItem:-1
  
    };
  }

  updateEpisode = (newEpisode) => {
     this.setState({ currentEpisode:newEpisode})
  }

  updateMessageEpisode = (newEpisode) => {
     this.props.updateEpisode(newEpisode)
     this.setState({ currentEpisode:newEpisode})
  }

  openMenu = () => {
    this.setState({ menuOpen:true})
  }

  closeMenu = () => {
    this.setState({ menuOpen:false})
  }

  closeBlog = () => {
    this.setState({ selectedIcon:-1, menuOpen:false})
    this.setState({selectedBlogItem:-1})
  }

  tapBack = () => {
    if(this.state.selectedIcon !== -1) {
      if(this.state.selectedIcon.parent === -1){
        
        this.setState({ selectedIcon:-1})
        

      }else {
        var icon;
        for( var i = 0; i < icons.length; i++){ 
          if ( this.state.selectedIcon.parent ===  icons[i].id) {
            icon = icons[i]
            this.setState({ selectedIcon:icon})
          }
        }
      }
    }
  }


  handleClickIcon = (iconId) => {
    

    var icon = null;
    if(typeof iconId === 'string' && iconId.indexOf("blog_update")> -1) {
      icon = icons[0];
      this.setState({selectedBlogItem:iconId.split("blog_update_")[1]})
    }else {
      for( var i = 0; i < icons.length; i++){ 
        if ( parseInt(iconId) ===  icons[i].id) {
          icon = icons[i]
         
        }
      }
    }
    

    if(icon === null ) {
      alert("document you are trying to viewed cannot be accessed")
    }else {
      this.setState({ selectedIcon:icon})
    }
    
  }


  render() {
    const iconviews = icons.map((icon, index) => {
      if(icon.episode <= this.state.currentEpisode){
        if(this.state.selectedIcon === -1) {
          if (icon.parent === -1){
              return (
                <Icon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} /> 
              )
              }else {return false}
        }else if(icon.parent === this.state.selectedIcon.id ) {
          return (
              <Icon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} /> 
            )
        }else {
          return false
        }
      }
      
    })

   
    
    return (
      <>

         {this.state.selectedIcon != -1 && (
            <div className="keepsafe-header"><button className="back-button desktop" onClick={this.tapBack} >BACK</button> <h2>{this.state.selectedIcon.name}</h2><div className="header-line"></div></div>
         )} 

        
         <div className="keepsafe-container " >
                
            {(this.state.selectedIcon === -1 || this.state.selectedIcon.type === "folder") && (
                <div className="desktop menuStorage">
                  <StorageMenuContent tapMenu={this.closeBlog.bind(this)}/>
                </div>
            )}
           
            {(this.state.selectedIcon === -1 || this.state.selectedIcon.type === "folder")? (
              <div className="keepsafe-container-content">
                  <ul className="keepsafe-iconlist">
                    {iconviews}
                  </ul>
              </div>
            ) : (
              <MediaView icon={this.state.selectedIcon} />
            )}

          </div>


            {this.state.selectedIcon === -1 
              ? (<img className="menu-toggle menu-toggle-icon mobile" alt="menu-toggle" onClick={this.openMenu} src="https://cdn.huntakiller.com/huntakiller/s10/menu-hamburger.svg"/>)
              : (<img className="back-arrow mobile" alt="back-arrow" onClick={this.tapBack} src="https://cdn.huntakiller.com/huntakiller/s10/back-arrow.svg"/>)
            }

            <CheeseburgerMenu
              isOpen={this.state.menuOpen}
              closeCallback={this.closeMenu.bind(this)}  className={"menuStorage"}>
              <StorageMenuContent tapMenu={this.closeBlog.bind(this)}/>
            </CheeseburgerMenu>

         {(this.state.selectedIcon != -1 && this.state.selectedIcon.type === "ashleyBlog") && (
            <AshleyBlogView  tapBack={this.closeBlog.bind(this)} currentEpisode={this.state.currentEpisode} selectedBlogItem={this.state.selectedBlogItem} updateMessageEpisode={this.updateMessageEpisode.bind(this)} />
         )} 

         {(this.state.selectedIcon != -1 && this.state.selectedIcon.type === "ashleyOfflinePortal") && (
            
            <AshleysOfflineMessageView   tapBack={this.closeBlog.bind(this)} />


          )} 



         
      </>


    )
  }




}