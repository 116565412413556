import React from 'react';
import { useHistory } from 'react-router-dom';
import Div100vh from 'react-div-100vh'

export default function HomeView(props) {

  

  return (
    <Div100vh  dontresize="true"  className="h-full w-full flex justify-center items-center z-10">
   		<div className="login-box  flex flex-col justify-center items-center p-4">
           
            <img src="https://cdn.huntakiller.com/huntakiller/s10/keepsafeprologo.svg" className="mb-6"/>

            
            <button className="rounded-full mx-12 mt-10 mb-8 w-full text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline" onClick={function(){props.tapButton("messages");}} >
                MESSAGES
            </button>

            <button className="rounded-full mx-12  w-full text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline" onClick={function(){props.tapButton("storage");}} >
                STORAGE DRIVE
            </button>
	        
        </div>

        <div className="footer" >
        	<button onClick={props.logout}>LOG OUT</button>
          	<p>© KEEPSAFE PRO INC.</p>
        </div>


    </Div100vh> 


  )




}