import React from 'react';

export default function ConversationYNWZZPK(props) {

  return (
  	<>
      <div className="timestamp my-2">4:24 PM</div>
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">we really shouldnt be messaging on here</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">even encrypted apps like this</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">i dont trust them</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7"> all i'll say is TFC isnt the man i believed in</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-sent col-7 offset-5">would you rather we meet in person</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">yes please and thank you</div>
  		</div>
  	
  	

  	</>
  
  );
  
}
