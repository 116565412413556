
import React, { Component } from 'react'
import ChatView from '../components/Messages/ChatView'
import ChatConversations from  '../components/Messages/ChatConversations'
import ReggieEpilogueChat from '../components/Messages/ReggieEpilogueChat'
import store from 'store';

import EnterEmailView from '../components/inbox/EnterEmailView'

export default class MessagesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedConversation: -1,
      currentEpisode:this.props.currentEpisode,
      userEmail:store.get("userEmail")
  
    };

    this.chatViewRef = React.createRef();
  }

 


  updateEpisodeFromMessage = (newStep) => {
    this.props.updateEpisode(newStep)
  }

  openAttachement = (id) => {
    this.props.openAttachement(id)
  }

  newMessageHandler = () => {
    this.props.newMessageHandler()
  }

  setCurrentConversation = (number) => {
    this.setState({ selectedConversation:number})
  }

  tapBack = () => {
  	this.setState({ selectedConversation:-1})
  }

  closeEmailPopUp = (email) => {
    this.setState({ userEmail:email})
  }

  updateEpisode = (newEpisode) => {
     this.setState({ currentEpisode:newEpisode})
     this.chatViewRef.current.updateEpisodeFromStorage(newEpisode)
     
  }


  render() {

    if(this.state.userEmail === null || this.state.userEmail === undefined) {
      return (
        <>
          <EnterEmailView closeCallback={this.closeEmailPopUp.bind(this)}/>

        </>
        )
    }else {
      return (
      <>

      <div className="keepsafe-container message-container-content" >
                
         
      <div className={this.state.selectedConversation === -1 ? "menuStorage" : "menuStorage desktop"} >
        <ChatConversations classToSet={this.state.selectedConversation === -1 ? "menuStorage" : "menuStorage desktop"} currentEpisode={this.state.currentEpisode} selectedConversation={this.state.selectedConversation} setCurrentConversation={this.setCurrentConversation.bind(this)}/>
      </div>
        
        <div className={this.state.selectedConversation === -1 ? "keepsafe-container-content desktop" : "keepsafe-container-content"}>
          

          <ChatView classToSet={(this.state.selectedConversation !== 1 && this.state.selectedConversation !== -1)  && "hide"} ref={this.chatViewRef} userEmail ={this.state.userEmail} currentEpisode={this.state.currentEpisode} updateEpisode={this.updateEpisodeFromMessage.bind(this)} openAttachement={this.openAttachement.bind(this)} newMessageHandler={this.newMessageHandler.bind(this)}  />
          

          <ReggieEpilogueChat  classToSet={(this.state.selectedConversation !== 2)  && "hide"} />

            


          
        </div>

      </div>

      {this.state.selectedConversation !== -1 && (<img className="back-arrow mobile" alt="back-arrow" onClick={this.tapBack} src="https://cdn.huntakiller.com/huntakiller/s10/back-arrow.svg"/>)}




      </>
    
    );
    }}

  
  
}