import React, { Component } from 'react'




import { Player } from './models/player';

import ChatClient from './components/ChatClient/chat-client';

var sendKlaviyoEvent = require('./utils/klaviyo.js').sendKlaviyoEvent;


export default class Inbox extends Component {
    constructor(props) {
        super(props);

        //this.emailClient = React.createRef()
        this.currentSeasonIndex = 10
        

        fetch('./config/config_10.json').then(response => {
          return response.json();
        }).then(data => {
          // Work with JSON data here
          //console.log(data.botConfiguration.stepsPerEpisode);
          this.setState({ stepsPerEpisode: data.botConfiguration.stepsPerEpisode})

        }).catch(err => {
          // Do something for an error here
        });



        this.state = {
            showEmailClient: true,
            userEmail:this.props.userEmail,
            stepsPerEpisode:[],
            currentEpisodeIndex:this.props.currentEpisode
        };

    }

    triggerEmail = (message) => {
        //alert("Trigger inbox MOM"+message)
        this.refs.chatClient.loadBridgeEmailGroupAnswer(message)

        /*this.refs.chatClient.current.loadBotAnswer(message)*/
    }

    closeModal = (e) => {
        this.props.tapBack()
    };

    updateGameContext = (newStep) => {
        
        this.setState({ currentEpisodeIndex:newStep})
        this.refs.chatClient.updateGameContext(newStep);
        if(newStep === 6.3) {
            this.refs.chatClient.sendEpilogueMessage()
        }
    }


    handleBotEmailResponse = (botResponse) => {
        // this.refs.chatClient.sendKillSwitchMessage()

        const correctQuestion = botResponse.indexOf('{{CorrectQuestion}}');
        //const correctQuestion = botResponse.indexOf('{{CorrectAnswer}}');
        this.props.newMessageHandler()

        if (correctQuestion !== -1) {
            var currentEpisodeIndex = this.state.currentEpisodeIndex
            // Call API
            //console.log('Correct'+currentEpisodeIndex);

            let newStep = this.state.currentEpisodeIndex + (Number.isInteger(this.state.currentEpisodeIndex) ? 0.2 : 0.1);
            this.props.updateEpisode(newStep)
            
            if(this.state.stepsPerEpisode[Math.floor(this.state.currentEpisodeIndex)] > 1) {
                var maxStep = Math.floor(this.state.currentEpisodeIndex)+this.state.stepsPerEpisode[Math.floor(this.state.currentEpisodeIndex)]/10
                
                //alert(this.state.currentEpisodeIndex)
                //alert(maxStep)

                if(this.state.currentEpisodeIndex < maxStep) {
                    
                    if(newStep===6.2) {
                        this.refs.chatClient.sendKillSwitchMessage()
                    }

                    //sendKlaviyoEvent("HAK100"+parseInt(this.state.currentEpisodeIndex),this.state.userEmail);
                    
                    this.setState({ currentEpisodeIndex:newStep})
                    this.refs.chatClient.updateGameContext(newStep);

                    //this.refs.chatClient.sendKillSwitchMessage()
                    

                    //this.props.updateEpisode(newStep)

                    

                }else {
                    
                    sendKlaviyoEvent(this.state.currentEpisodeIndex,this.state.userEmail);

                    var event = new CustomEvent('CHARACTERRESPONSE', {detail :  { "season":"hak10", "episode":"0"+parseInt(this.state.currentEpisodeIndex), "success": "hak10"+"0"+parseInt(this.state.currentEpisodeIndex)} });
                    window.dispatchEvent(event);
                }
            }else {
                sendKlaviyoEvent(this.state.currentEpisodeIndex,this.state.userEmail);

                var event = new CustomEvent('CHARACTERRESPONSE', {detail :  { "season":"hak10", "episode":"0"+parseInt(this.state.currentEpisodeIndex), "success": "hak10"+"0"+parseInt(this.state.currentEpisodeIndex)} });
                window.dispatchEvent(event);
            }


            


        } else {
            console.log('Incorrect');
        }
    }


    handlepenAttachement = (id) => {
        this.props.openAttachement(id)
    }



    render() {
        // Render appointment list if active.
        // playerName and playerAvatar values are need to invoke client.
        const renderEmailClient = () => {

            if (this.state.showEmailClient ) {

                const player = new Player();
                player.email = this.state.userEmail;
                player.name = '';
                player.avatar = '';

                const configurationLocation = '/config/config_10.json';


                return <ChatClient player   = {player}
                    configurationLocation   = {configurationLocation}
                    botChatResponse         = {this.handleBotEmailResponse}
                    currentEpisode          = {this.state.currentEpisodeIndex}
                    openAttachement         = {this.handlepenAttachement}
                    ref                         = "chatClient"

                />;
            }
            return null;
        };



        return (
          <>
                <div className={this.props.classToSet ? this.props.classToSet+" HAKEmailChatContainer" : "HAKEmailChatContainer"}>

                     <div className=" header-chat text-center pb-4">

                            <img className="mx-auto" src="https://cdn.huntakiller.com/huntakiller/s10/chat_frannie_avatar.svg" />

                            
                              <p className="userName text-white font-medium font-bold md:text-xl">Frannie</p>
                            


                      </div>


                    {this.props.userEmail  !== undefined && (renderEmailClient())}
                   
                </div>

          </>
        )
    }
}
