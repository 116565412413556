import React, { useState, useEffect } from 'react';
import store from 'store';
import DesktopView from './views/DesktopView'
//import { users } from './datas/users.json';
import { icons } from './datas/season10/icons.json';
import { useHistory } from 'react-router-dom';
var getUser = require('./utils.js').getUser;


const USERS = JSON.parse(process.env.REACT_APP_EPISODE_PW);


export default function KeepSafePro() {
 
    const history = useHistory();
	const [currentEpisode, setCurrentEpisode] = useState(0)
	const [user, setUser] = useState(null)

	useEffect(() => { 
		const password = store.get("passwordHAK10");
		if (password == null || password === false){
	    	console.log("notloggedin")
	    	history.push('/')
	    }else{
	    	const user = getUser(password, USERS);
	    	//console.log(this.user)
	    	if (user != null ){
	    		setUser(user)
				setCurrentEpisode(user.episode)
			}else{
				history.push('/')
			}
	    }

	}, [history]);

	
	return (
		<>
	  {(user !== null) && (
         	  <DesktopView className="keepsafepro" icons={icons} seasonurl="keepsafepro" currentEpisode={currentEpisode} />
        )}
	  </>


	)
}

