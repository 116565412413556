import React, { useState, useEffect } from 'react';
import store from 'store';

export default function LoginView(props) {
  
  const correctPassword = props.correctPw;

  const [password, setPassword] = useState("");
  const [errorPw, setErrorPw] = useState(false);


  

  const handleChange = (event) => {
    setPassword(event.target.value)
    setErrorPw(false)
    
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      //console.log('enter press here! ')
      document.activeElement.blur();
      event.preventDefault();
    }
  }


  const handleSubmit = (event) => {

    event.preventDefault();
    document.getElementById("password").blur();
    
    

    if(correctPassword === password.toLowerCase()) {
    	store.set('ashleyOfflinePortal', password);
		setTimeout(
		    function() {
		        props.goToHomeView()
		    }
		    .bind(this),
		    500
		);
    }else {
    	setErrorPw(true)
    }
    
  }

  

  return (
   <div className="login-box  flex flex-col justify-center items-center p-4">
           
		<img src="https://cdn.huntakiller.com/huntakiller/s10/ashelysofflinemessage/ashleys-offline-messages-logo.svg" />


		<form className=" w-full" onSubmit={handleSubmit}>
		  <div className="">

		        <div className=" mb-3 mx-4 md:mx-12">
		        <input className="rounded-full shadow appearance-none border-none  w-full py-4 px-6 mb-4  leading-tight focus:outline-none focus:shadow-outline bg-transparent" value="AshleyMendoza" disabled={true}/>

		      <input className="rounded-full shadow appearance-none border-none  w-full py-4 px-6  leading-tight focus:outline-none focus:shadow-outline bg-transparent" value={password}
		  onChange={handleChange} type="password" autoFocus id="password" placeholder="**********" onKeyPress={handleKeyPress}/>

		          <p className="error mt-2">{errorPw && "Invalid Password"} &nbsp;</p>
		        </div>
		  </div>
		  <div className="flex items-center justify-between">
		  <button className="rounded-full mx-8  w-full text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline" id="episodeLogin" type="submit" disabled={false}>
		    LOG IN
		  </button>
		 
		</div>
		</form>

	</div>
  );
}