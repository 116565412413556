import React, { useState, useEffect } from 'react';
import store from 'store';
import Div100vh from 'react-div-100vh';
import { useHistory } from 'react-router-dom';
import { getUser } from './utils.js';
const USERS = JSON.parse(process.env.REACT_APP_EPISODE_PW);

export default function EpisodeLogin() {
  
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [errorPw, setErrorPw] = useState(false);


  useEffect(() => { 
    const passwordStorage = store.get("passwordHAK10")
    
    if (!(typeof passwordStorage === 'undefined')) {
      history.push("keepsafepro")

    }

  }, [history])

  const handleChange = (event) => {
    setPassword(event.target.value)
    setErrorPw(false)
    
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      //console.log('enter press here! ')
      document.activeElement.blur();
      event.preventDefault();
    }
  }


  const handleSubmit = (event) => {

    event.preventDefault();
    document.getElementById("password").blur();
    
    var user = getUser(password, USERS);

    if(user !== null) {
    	store.set('passwordHAK10', password);

		setTimeout(
		    function() {
		        history.push("keepsafepro")
		    }
		    .bind(this),
		    500
		);
    }else {
    	setErrorPw(true)
    }
    
  }

  

  return (
   <Div100vh  dontresize="true"  className="h-full w-full flex justify-center items-center z-10 seasonDesktop">

          <div className="login-box  flex flex-col justify-center items-center p-4">
           
            <img src="https://cdn.huntakiller.com/huntakiller/s10/keepsafeprologo.svg" />
            
            <h3 className="mt-10 mb-10">Welcome, <span className="blueColor">DETECTIVE</span></h3>

            <form className=" w-full" onSubmit={handleSubmit}>
              <div className="">

                    <div className=" mb-3">
                  <input className="rounded-full shadow appearance-none border-none  w-full py-5 px-5 text-center  leading-tight focus:outline-none focus:shadow-outline bg-transparent" value={password}
              onChange={handleChange} type="password" autoFocus id="password" placeholder="**********" onKeyPress={handleKeyPress}/>
                      <p className="error mt-2">{errorPw && "Invalid Password"} &nbsp;</p>
                    </div>
              </div>
              <div className="flex items-center justify-between">
              <button className="rounded-full mx-8  w-full text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline" id="episodeLogin" type="submit" disabled={false}>
                LOG IN
              </button>
             
            </div>
            </form>

          </div>

          <div className="footer" >
          	<p>© KEEPSAFE PRO INC.</p>
          </div>
        </Div100vh>
  );
}