import React from 'react';

export default function Header(props) {

  const openMenu = () => {
    props.openMenu();
  }

  return (
    
    <>

          <div className="desktop top-bar">
            <button className={ "messages" === props.currentView ? 'current' : ' ' } onClick={function(){props.tapButton("messages");}} > MESSAGES </button>
            <button  className={ "storage" === props.currentView ? 'current' : ' ' } onClick={function(){props.tapButton("storage");}} > STORAGE </button>
            

            {props.currentView !== "home" && (<button className="logout" onClick={function(){props.logout();}} >LOG OUT</button>)}
          </div>
         
          <div className="mobile top-bar">
           <div className="empty-space-mobile-left"></div>
           {props.currentView === "messages" && (<h1>messages</h1>)}
          
           
           <img className=" close-icon mobile"  alt="close-dt" onClick={function(){props.tapButton("home");}} src="https://cdn.huntakiller.com/huntakiller/s10/closebutton.svg"/>
          

          </div>
           
          {props.currentView === "storage" && (<div className="header-line" />)}



     </>
  


  )




}