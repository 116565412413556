export interface MessageVM {
    sent: boolean;
    content: string;
    delivery: string;
}

export class Message implements MessageVM {
    sent: boolean = true;
    content: string = '';
    delivery: string = '';
}
